<template>
    <div :class="size">
        <v-card>
            <v-card-title class="font-weight-medium">
                App-e
                <v-divider class="mx-4" vertical></v-divider>
                <div class="text--secondary subtitle-2 mr-4" style="font-size:0.65em !important">
                    Nouveautés
                </div>
                <div class="text--secondary subtitle-2 mr-4" style="font-size:0.65em !important">
                    Widgets
                </div>
                <v-menu bottom offset-x>
                    <template v-slot:activator="{ on, attrs }">
                        <div class="text--secondary subtitle-2 mr-4" style="font-size:0.65em !important" v-bind="attrs" v-on="on">
                            Raccourcis
                            <v-icon>mdi-menu-down</v-icon>
                        </div>  
                    </template>

                    <v-list dense class="py-0" color="primary">
                        <v-list-item v-for="i in 5" :key="i" link>
                            <v-list-item-title>raccourcis : {{ i }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-spacer></v-spacer>
                <div class="mr-6">
                    <v-text-field 
                        class="subtitle-1" 
                        hide-details 
                        placeholder="Rechercher" 
                        dense 
                        single-line 
                        append-outer-icon="mdi-magnify">
                    </v-text-field>
                </div>
                <v-badge class="mr-6" dot bordered color="red">
                  <v-icon >mdi-message-text-outline</v-icon>
                </v-badge>
                <v-badge dot bordered color="red">
                  <v-icon>mdi-bell-outline</v-icon>
                </v-badge>
            </v-card-title>
        </v-card>
    </div>
</template>

<script>
export default {
    name : 'Search',
    props : {
        size : {
            type : String,
            default : 'md'
        }
    },
}
</script>

<style lang="scss" scoped>
.widget {
    @media screen and (max-width: 600px) {
        grid-column: span 2;
        grid-row: span 2;
        
    }
    @media screen and (min-width: 600px) {
        &.md {
            grid-column: span 4;
            grid-row: span 1;
        }
    }
    @media screen and (min-width: 960px) {
        &.md {
            grid-column: span 6;
            grid-row: span 1;
        }
    }
    @media screen and (min-width: 1200px) {
        &.md {
            grid-column: span 8;
            grid-row: span 1;
        }
    }
}
</style>